import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/common/shared.service';

@Component({
  selector: 'app-user-sidebar',
  templateUrl: './user-sidebar.component.html',
  styleUrls: ['./user-sidebar.component.scss']
})
export class UserSidebarComponent implements OnInit {

  constructor(private sharedService: SharedService) { }
  public sidebarActive:boolean;
  ngOnInit() {
  }

  tooglemenu(e){
    e.stopPropagation();
    this.sidebarActive = !this.sidebarActive;
    this.sharedService.updateComp1Val(this.sidebarActive);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-content',
  templateUrl: './login-content.component.html',
  styleUrls: ['./login-content.component.scss']
})
export class LoginContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

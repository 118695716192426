import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  sidebarActive: string;
  _comp1ValueBS = new BehaviorSubject<string>('');

  constructor() {
      this.sidebarActive;
      this._comp1ValueBS.next(this.sidebarActive);
  }

  updateComp1Val(val) {
    this.sidebarActive = val;
    this._comp1ValueBS.next(this.sidebarActive);
  }
}

import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup,  Validators,FormBuilder} from '@angular/forms';
import { UserLoginService } from 'src/app/core/services/user-login.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
 providers: [MessageService]
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  error: any;
  isLoading:boolean;

  constructor(private route: ActivatedRoute,private router: Router,private UserLoginService: UserLoginService,
    private formBuilder: FormBuilder,public messageService: MessageService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
    });
  }
  get f() { return this.loginForm.controls; }

  // onSubmit() {

  //   this.submitted = true;
  //   if(this.loginForm.value.name =='admin' && this.loginForm.value.password == 'admin') {
  //       localStorage.setItem('protek-user', 'admin');
  //       this.router.navigate(['./admin-dashboard']);
  //       console.log(this.loginForm.value,'sssss');
  //   }
  //   if(this.loginForm.value.name =='user' && this.loginForm.value.password == 'user123'){

  //     localStorage.setItem('protek-user', 'user');
  //     this.router.navigate(['./user-dashboard']);
  //     console.log(this.loginForm.value,'sssss');
  //   }

  // }
  onSubmit(){
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    if(this.loginForm.valid){
      this.isLoading= true;
    }
    this.UserLoginService.onSignup(this.loginForm.value).subscribe((res: any)=>{
     this.isLoading = false;
      if(res.StatusCode === "1" && res.Data.isAdmin) {
        console.log(res.Data)
        localStorage.setItem('protek-user',  JSON.stringify(res.Data));
        this.router.navigate(['./admin-dashboard']);
      }
      if(res.StatusCode === "1" && !res.Data.isAdmin) {
        console.log(res.Data)
        localStorage.setItem('protek-user', JSON.stringify(res.Data));
        this.router.navigate(['./user-dashboard']);
      }
      if(res.StatusCode === "0") {
        this.messageService.add({
          severity: 'error',
          summary: 'Warning',
          detail: 'User not approved'
        });
      }
      if(res.StatusCode === "1") {
        this.messageService.add({
          severity: 'success',
          summary: 'Login',
          detail: 'Login successfully'
        });
      }
      if(res.StatusCode === "2") {
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: 'User Terminated'
        });
      }
      if(res.StatusCode === "3") {
        this.messageService.add({
          severity: 'error',
          summary: 'error',
          detail: 'Invalid Username or Password'
        });
      }
       else {
        this.error = res.Message;
      }
      this.loginForm.markAsPristine();
      this.loginForm.markAsUntouched();
    })
  }
}

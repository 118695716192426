import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'employee-portal';
  hideHeader: boolean;
  sidebar:boolean;
  isAdmin:boolean;
  isUser:boolean;
  isLogin:boolean;
  constructor(private router: Router) {
    this.router.events.subscribe((res) => {
      const url = this.router.url.split('?')[0];
      if(url === '/login' || url === '/login/forgot-password' ||  url === '/login/signup') {
        this.hideHeader = false;
      } else {
        this.hideHeader = true;
      }
    })
  }

  ngOnInit() {
    this.hideHeader = false;
    
    if(window.location.href.indexOf('login')>0){
      this.isLogin = true;
      this.isAdmin = false;
      this.isUser = false;
    }
    if(window.location.href.indexOf('user')>0){
      this.isUser = true;
      this.isAdmin = false;
    }
    if(window.location.href.indexOf('admin')>0){
      this.isAdmin = true;
      this.isUser = false;
    }
  }
}

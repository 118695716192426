import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
      path: '',
      redirectTo: '/login',
      pathMatch: 'full',
    },
  { path: 'login', loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule) },
  { path: 'admin-dashboard', loadChildren: () => import('./features/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule) },
  { path: 'admin-timesheets', loadChildren: () => import('./features/admin-timesheets/admin-timesheets.module').then(m => m.AdminTimesheetsModule) },
  { path: 'admin-request-letter', loadChildren: () => import('./features/admin-request-letter/admin-request-letter.module').then(m => m.AdminRequestLetterModule) },
  { path: 'admin-perfomance-evaluation', loadChildren: () => import('./features/admin-perfomance-evaluation/admin-perfomance-evaluation.module').then(m => m.AdminPerfomanceEvaluationModule) },
  { path: 'admin-training-modules', loadChildren: () => import('./features/admin-training-modules/admin-training-modules.module').then(m => m.AdminTrainingModulesModule) },
  { path: 'add-user', loadChildren: () => import('./features/add-user/add-user.module').then(m => m.AddUserModule) },
  { path: 'admin-status-reports', loadChildren: () => import('./features/admin-status-reports/admin-status-reports.module').then(m => m.AdminStatusReportsModule) },

  { path: 'user-timesheet', loadChildren: () => import('./features/user-timesheet/user-timesheet.module').then(m => m.UserTimesheetModule) },
  { path: 'user-request-letter', loadChildren: () => import('./features/user-request-letter/user-request-letter.module').then(m => m.UserRequestLetterModule) },
  { path: 'user-perfomance-evaluation', loadChildren: () => import('./features/user-perfomance-evalution/user-perfomance-evalution.module').then(m => m.UserPerfomanceEvalutionModule) },
  { path: 'user-status-report', loadChildren: () => import('./features/user-status-report/user-status-report.module').then(m => m.UserStatusReportModule) },
  { path: 'user-dashboard', loadChildren: () => import('./features/user-dashboard/user-dashboard.module').then(m => m.UserDashboardModule) },
  { path: 'user-documents', loadChildren: () => import('./features/user-documents/user-documents.module').then(m => m.UserDocumentsModule) },
  { path: 'usertraining-modules', loadChildren: () => import('./usertraining-modules/usertraining-modules.module').then(m => m.UsertrainingModulesModule) },
  { path: 'approve-user-signup', loadChildren: () => import('./features/approve-user-signup/approve-user-signup.module').then(m => m.ApproveUserSignupModule) },




];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

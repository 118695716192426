import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder,Validators, FormControl,} from '@angular/forms';
import { UserSignupService } from 'src/app/core/services/user-signup.service';
import { PrimeNGConfig } from 'primeng/api';
//import {SelectItem} from 'primeng/api';
import {MessageService} from 'primeng/api';

interface ProjectSuperiorNames {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-login-signup',
  templateUrl: './login-signup.component.html',
  styleUrls: ['./login-signup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})
export class LoginSignupComponent implements OnInit {
  signupForm: FormGroup;
  submitted = false;
  //projectSuperiorNames:SelectItem[];
  projectSupervisior:any;
  formattedDate:any;
  selectedValue:string;
  isLoading:boolean;
  dobPattern="^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$";

  projectSuperiorNames: ProjectSuperiorNames[] = [
    {viewValue: 'Chakravarthy Bharath', value:'Chakravarthy Bharath'},
    {viewValue: 'Anoop Chahal', value:'Anoop Chahal'},
    {viewValue: 'Vikram Sharma', value:'Vikram Sharma'},
  ];

  constructor(private formBuilder: FormBuilder, private userSignupService: UserSignupService,
    private primengConfig: PrimeNGConfig,private messageService: MessageService) {
  }


  ngOnInit() {
    this.primengConfig.ripple = true;
    this.signupForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      email:['', [Validators.required, Validators.email]],
      userPassword:['', Validators.required],
      dob:['', [Validators.required ,Validators.pattern(/^(((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))[-/]?[0-9]{4}|02[-/]?29[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/)]],
      phoneNo:['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      projectSupervisior:['', [Validators.required]],

    });

  }
  get f() { return this.signupForm.controls; }

    onSubmit(){
      console.log(this.signupForm.value);
      this.submitted = true;
      if (this.signupForm.invalid) {
        return;
      }
      if(this.signupForm.valid){
        this.isLoading= true;
      }
      const signupObj={
        "fullName": this.signupForm.get('fullName').value,
        "email": this.signupForm.get('email').value,
        "userPassword": this.signupForm.get('userPassword').value,
        "dob": this.signupForm.get('dob').value,
        "phoneNo": this.signupForm.get('phoneNo').value,
        "projectSupervisior": this.projectSupervisior,
      }

      this.userSignupService.onSignup(signupObj).subscribe((res: any)=>{
        this.isLoading = false;
        if(res.StatusCode === "0") {
          this.messageService.add({
            severity: 'error',
            summary: 'error',
            detail: 'Login failed'
          });
        }
        if(res.StatusCode === "1") {
          this.messageService.add({
            severity: 'success',
            summary: 'Login',
            detail: 'Login successfully'
          });
        }
        if(res.StatusCode === "2") {
          this.messageService.add({
            severity: 'error',
            summary: 'error',
            detail: 'Contact number exists'
          });
        }
        if(res.StatusCode === "3") {
          this.messageService.add({
            severity: 'error',
            summary: 'error',
            detail: 'Email already exists'
          });
        }
        this.signupForm.reset();
        this.signupForm.markAsPristine();
        this.signupForm.markAsUntouched();
    })
  }
}

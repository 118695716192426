import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginContentComponent } from './login-content/login-content.component';
import { LoginSignupComponent } from './login-signup/login-signup.component';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import {InputTextModule} from 'primeng/inputtext';
import {CheckboxModule} from 'primeng/checkbox';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {DropdownModule } from 'primeng/dropdown';
import {CalendarModule} from 'primeng/calendar';
import {RippleModule} from 'primeng/ripple';

import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';

import {ToastModule} from 'primeng/toast';



@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent, LoginContentComponent, LoginSignupComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    InputTextModule,
    CheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    RippleModule,
    DropdownModule,
    InputTextareaModule,
    MatSelectModule,
    ToastModule,
    MatInputModule
  ],
  providers: [],

})
export class LoginModule { }

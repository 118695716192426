import { Component, OnInit} from '@angular/core';
import { SharedService } from 'src/app/common/shared.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  constructor(private sharedService: SharedService) { }
  public sidebarActive:boolean;
  public usr : any;
  ngOnInit() {
    this.usr = JSON.parse(localStorage.getItem('protek-user'));
  }

  tooglemenu(e){
    e.stopPropagation();
    this.sidebarActive = !this.sidebarActive;
    this.sharedService.updateComp1Val(this.sidebarActive);
  }
  

}

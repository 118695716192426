import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const userLogin = baseUrl + 'User/UserLogin';
@Injectable({
  providedIn: 'root'
})
export class UserLoginService {

  constructor(private http: HttpClient) { }

  onSignup(loginvalue){
    return this.http.post(userLogin, loginvalue);
  }
}

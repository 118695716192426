import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const userSignup = baseUrl + 'User/UserSignUp';

@Injectable({
  providedIn: 'root'
})
export class UserSignupService {

  constructor(private http: HttpClient) { }

  onSignup(signupvalue){
    return this.http.post(userSignup, signupvalue);
  }

 
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { LoginModule } from '../features/login/login.module';
import { UserSidebarComponent } from './components/user-sidebar/user-sidebar.component';
import { HttpClientModule } from '@angular/common/http';
import { UserSignupService } from './services/user-signup.service';

@NgModule({
  declarations: [SidebarComponent,  UserSidebarComponent],
  imports: [
    CommonModule,
    RouterModule,
    LoginModule,
    HttpClientModule
  ],
  providers: [UserSignupService],
  exports:[SidebarComponent,UserSidebarComponent]
})
export class CoreModule { }
